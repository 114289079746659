import React from "react";
import Card from 'react-bootstrap/Card';
import CardGroup from 'react-bootstrap/CardGroup';
import Container from 'react-bootstrap/Container';
import styled from "styled-components";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

const IconBox = styled.div`
    width: 110px;
    height: 106px;
    border-radius: 50%;
    margin: 0 auto;
    position: relative;
    margin-top:15px;
    box-shadow: 0 0 0 10px #0078ff;
    transition: all 300ms ease;
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    i{
        font-size: 50px;
        justify-content: center;
        display: flex;
        position: absolute;
        top: 25px;
        right: 0;
        left: 0; 
    }
    &:hover{
        box-shadow: 0 0 0 0 #0078ff;
        background-color: #0078ff;
        color: #fff;
    }

`

// const ServAnimation = keyframes`

//     0% {
//     width: 180px;
//     background-color: #0d86f0;
//     height: 2px;
//   }

//   100% {
//      width: 0;
//      height: 2px;

//   }

// `



const Serviceheader = styled.div`
  text-decoration: none;
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align:center;
  position: relative;
  margin-bottom: 20px;
  

`
function Services(){
    return(
        <Container>
            <AnimationOnScroll initiallyVisible={true} duration={5} animateIn="animate__swing"><Serviceheader id="service">Services</Serviceheader></AnimationOnScroll>

            <AnimationOnScroll animateIn="animate__bounceIn">
            
            <CardGroup className="Cardgroup-services">
            <Card className="Card-Services">
                    <IconBox>
                        <i className="fa fa-desktop boxess" ></i>
                    </IconBox>
                <Card.Body>
                    <Card.Title>Web Development</Card.Title>
                    <Card.Text>
                                I have expertise in HTML, CSS, and JavaScript and frequently incorporate frameworks like Bootstrap, jQuery, and REACT into my projects. I am particularly passionate about learning and utilizing React technology.
                            <br /><br />
                                In my role as a junior web developer, I am primarily responsible for converting PSD files into static, pixel-perfect websites using HTML, CSS, Bootstrap, and jQuery. Additionally, I address any quality issues identified during the site review process by the QA team. I am also adept at modifying existing site layouts to align with client needs.


                    </Card.Text>
                </Card.Body>
            </Card>
                {/* <Card className="Card-Services">
                    <IconBox>
                        <i className="fa fa-desktop boxess" ></i>
                    </IconBox>
                <Card.Body>
                    <Card.Title>Android Development</Card.Title>
                    <Card.Text>
                        Basic knowledge of android Development and GUI Design by using Kotlin/Java and XML
                    </Card.Text>
                </Card.Body>
            </Card> */}
                <Card className="Card-Services">
                    <IconBox>
                    <i className="fa fa-desktop boxess" ></i>
                    </IconBox>
                
                <Card.Body>
                    <Card.Title>Windows Web server admin</Card.Title>
                    <Card.Text>
                                I began my professional journey as a web server administrator, where I undertook a range of tasks. My primary responsibilities included configuring sites in the IIS server and transferring files via FTP. I was also responsible for site migration from development to production environments, ensuring seamless transitions.

                                <br/><br/>Additionally, I used IIS's built-in Rewrite features to redirect URLs, or manually created rules and edited existing ones in the web.config file. I played a pivotal role in assisting clients in changing their domain DNS records to point to Revize live servers.

                                Lastly, I ensured the security of live sites by installing SSL certificates, thereby guaranteeing a safe and secure browsing experience for users.
                    </Card.Text>
                </Card.Body>
            </Card>
        </CardGroup>
            </AnimationOnScroll>
            </Container>

    )
}

export default Services;