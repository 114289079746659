import React from 'react'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import styled from "styled-components";
import Skills from './Skills';
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import Resume from "../documents/Niemur Obi Resume.pdf"

const SkillsImg = styled.img`
    height: 150px;
    width: 150px;
    border-radius: 50%;
    text-align: center;
`

const Progress = styled.h3`
  background-color: #ccddee;
  display: flex;
  justify-content: center;
  text-align: center;
  font-family: sans-serif;
  color: #b19cd9;
`;



function About() {
    return (
        <Container>
            <div className='about-box' id="about-obi">
                <Row className='About-skill-header'>

                    <Col lg={6} sm={12}>
                        <AnimationOnScroll animateIn="animate__fadeInLeftBig">
                            <Row>
                                <Col lg={4} sm={1} md={4} className='skillPic'>
                                    <SkillsImg src='./images/img3_old.jpg' />
                                </Col>
                                <Col lg={6} sm={5} md={6}>
                                    <p><strong>Name: </strong> Niemur Rahman Obi</p>
                                    <p> <strong>Postion: </strong>Junior front-end developer</p>
                                    <p><strong>Eamil: </strong> niemur.rahman30@gmail.com</p>
                                    <p><strong>Phone: </strong>313-775-4299</p>
                                    {/* <p><strong>Resume: </strong><a href={Resume}>Download the Resume</a></p> */}
                                    
                                </Col>
                            </Row>
                            <Progress>Skills</Progress>
                            <div>
                                <Skills name="HTML" Progress="89%" />
                                <Skills name="CSS" Progress="85%" />
                                <Skills name="JS" Progress="80%" />
                                <Skills name="React" Progress="65%" />
                                <Skills name="Python" Progress="60%" />
                                <Skills name="JAVA" Progress="40%" />
                            </div>
                        </AnimationOnScroll>

                    </Col>




                    <Col lg={6} sm={12}>
                        <AnimationOnScroll animateIn="animate__fadeInRightBig">

                            <div className='About'>
                                <h1 className='about-header'>About Me</h1>
                                <p className='myDescription'> Hi, I'm Niemur Obi, a junior front-end developer based in Michigan, USA, with a bachelor's degree in information technology from Purdue Global University. I have a passion for technology and design, including software engineering and UI/UX. I'm also interested in games, movies, traveling, and staying active by going to the gym.
                                    <br></br><br></br>

                                    As a skilled developer, I am always open to new job opportunities where I can apply my knowledge and grow professionally. If you have a great opportunity that matches my skills and experience, please feel free to <a href="#contact"> contact me.</a>

                                </p>
                            </div>
                        </AnimationOnScroll>

                    </Col>

                </Row>
            </div>

        </Container>
    )


}
export default About;