import React from "react";
import styled from 'styled-components';
import Container from 'react-bootstrap/Container';
import "animate.css/animate.min.css";


const Overlaysection = styled.div`
    background: ${props => `url(${props.img})`};
    background-size: cover;
    height: 500px;
    max-height: 100%;
    background-repeat: no-repeat;
    position: relative;
    background-attachment: fixed;
    background-position: center top;
    margin-bottom: 30px;
    max-width: 100%;

  &::after {
  content: '';
  background-color: #0d86f0;
  width: 100%;
  max-width: 100%;
  height: 100%;
  position: absolute;
  opacity: 0.3;
  top: 0;
`

const Headtext = styled.p`
  color: #fff;
  font-weight: bold;
  font-size: 24px;
  position: absolute;
  bottom: 50%;
  @media (max-width: 768px) {
    bottom: 22%;
    text-align: left;
    padding: 2px;
    font-size: 24px;
  }
`

function Overlay({img, BodyText}){
    return(

        <Overlaysection img={img}>
            <Container>
                    <Headtext>{BodyText}</Headtext>
      
            </Container>
        </Overlaysection>

    )
}

export default Overlay;