import React from "react";
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Portfoliodata from "./Portfoliodata";
import Button from 'react-bootstrap/Button';
import styled from "styled-components";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';




const Serviceheader = styled.div`
  text-decoration: none;
  font-size: 2rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align:center;
  position: relative;
  margin-bottom: 20px;

`


function Portfolio() {
    return (
        <Container>
            <AnimationOnScroll animateIn="animate__fadeInLeftBig"><Serviceheader id="portfolio">Portfolio</Serviceheader></AnimationOnScroll>


            <AnimationOnScroll animateIn="animate__bounceIn">


                <Row xs={1} md={2} className="g-4 portfolio">
                    {Portfoliodata.map((data, index) => {
                        return (
                            <Col key={index}>
                                <Card className="portfolio-card" border="light">
                                    <Card.Img variant="top" src={data.img} className="portfolio-img" />
                                    <Card.Body className="portfolio-body">
                                        <Card.Title className="portfolio-title">{data.title}</Card.Title>
                                        <Card.Text>
                                            {data.text}
                                        </Card.Text>
                                        <Button variant="outline-info" href={data.src} target="blank">Click here to visit the site</Button>
                                        <Button variant="outline-info" href={data.gitsrc} target="blank">Click here for github link</Button>

                                    </Card.Body>

                                </Card>
                            </Col>

                        )
                    })}


                </Row>
            </AnimationOnScroll>
        </Container>

    )
}

export default Portfolio;