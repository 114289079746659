import React from "react";
import {Carousel} from 'react-bootstrap';
import "animate.css/animate.min.css";


function Slider(){
    return(
        <Carousel>
            
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src='images/img3.png'
                    alt="First slide"
                />
                
                <Carousel.Caption>
                    <h3 className="carousel-animation">Niemur Rahman Obi</h3>
                    <div className="Caption">
                        <span>R</span>
                        <span>E</span>
                        <span>A</span>
                        <span>C</span>
                        <span>T</span>
                    </div>
                    {/* <div className="CaptionDiv">
                    <p className="captionP">Front End Developer</p>
                    </div> */}

                    <div className="container1">
                        <p className="title">
                            <span className="title-word title-word-1">Junior-</span>
                            <span className="title-word title-word-2">Front-</span>
                            <span className="title-word title-word-3">End-</span>
                            <span className="title-word title-word-4">Developer</span>
                        </p>
                    </div>

                    
                </Carousel.Caption>
            </Carousel.Item>
           
        </Carousel>

    )
}

export default Slider;
