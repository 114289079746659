import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import styled from "styled-components";
import Navbarcomponent from './components/Navbarcomponent';
import Slider from './components/Slider';
import About from './components/About';
import Services from './components/Services';
import Overlay from './components/Overlay';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Certificates from './components/Certificates';


const DefaultcolorBackground = styled.div`
background-color: #f5f5f5;;

`

function App() {

 
  return (
    <>

    <Navbarcomponent />
    <Slider />
      <DefaultcolorBackground>
    <About />
    <Services />
      </DefaultcolorBackground>

      <Overlay
        img='./images/overlay-pic.jpg'
        BodyText="As an enthusiastic and creative technologist with hands on skills in a wide range of software, tools and programming languages, I'm well positioned
                  to leverage my abilities and qualifications to thrive for next Developer in your company"
      />
      <DefaultcolorBackground>
      <Portfolio/>
      </DefaultcolorBackground>
      <Overlay
        img='./images/overlay-pic2.png'
        BodyText="Seeking a challenging software development opportunity in a dynamic environment where innovation, education and sense of ownership are valued and encouraged."
      />

      <Certificates />

      <Contact />
      <Footer />
      
    </>

   
  );
}

export default App;
