import React from 'react'
import logo from "../logo/logo-black2.png";
import { Container, Nav, Navbar } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';
// import AnchorLink from "react-anchor-link-smooth-scroll";

function Navbarcomponent() {
    return (
        <Navbar collapseOnSelect expand="lg" className='navcolor' variant="dark">
            <Container>
                <Navbar.Brand><HashLink smooth to="#" className='text-decoration'><img className='logoStyle' src={logo}/></HashLink></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav className="me-auto">
                    </Nav>
                    <Nav>
                        <Nav.Link><HashLink smooth to="/#about-obi" className='text-decoration'>About Me</HashLink></Nav.Link>
                        <Nav.Link><HashLink smooth to="/#service" className='text-decoration' href='#service'>Services</HashLink></Nav.Link>
                        <Nav.Link><HashLink smooth to="/#portfolio" className='text-decoration' href='#portfolio'>Portfolio</HashLink></Nav.Link>
                        <Nav.Link><HashLink smooth to="/#obiCertificates" className='text-decoration' href='#contact'>Certificates</HashLink></Nav.Link>
                        <Nav.Link><HashLink smooth to="/#contact" className='text-decoration' href='#contact'>Contact Me</HashLink></Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Navbarcomponent;