// import imgCompress from './images/imageCompressorPic.jpg'

export default [
  {
    img: "./images/text-editor-pic.png",
    src: "https://obi-text-editor-89d42.web.app/",
    title: "Text Editor by functional React",
    text: "This is a React text editor that stores data in the browser's local storage(which will be deleted if the cache and cookies are cleared).It was built using the React framework and various NPM packages and components such as React- mde for the editor, react - showdown and markdown for converter, and react - split for screen split.",
    id: 1,
    gitsrc: "https://github.com/obi-niemur/React-text-Editor/tree/master",
  },
  {
    img: "./images/resturentPic.jpg",
    src: "https://best-restaurant-e892a.web.app/",
    title: "Fully responsive best restaurant finder (Class based React) ",
    text: "If you want to find the best restaurant near by you. Just use this application built by me. This restaurant application using YELP API to find restaurant in three categories. those are (Best Matched, Highest Rated, Most Reviewed). Please follow the instruction on top of the page. there is a CORS error that API shows. I'm working on it to fix the issue asap",
    id: 2,
    gitsrc: "https://github.com/obi-niemur/FindBestResturent/tree/master",
  },
  {
    img: "./images/blog.jpg",
    src: "https://my-react-blog-by-obi.ue.r.appspot.com/",
    title: "My blog site by using MERN stack",
    text: "This site allows users to log in and vote/comment on articles. It's built on MERN stack with firebase authentication. Site is down due to expired trial period in Google Cloud, check GitHub link for code, use your own Google Cloud credentials and private keys to run locally (no credentials included for security).",
    id: 3,
    gitsrc: "https://github.com/obi-niemur/react-blog-by-Obi",
  },
  {
    img: "./images/photo-gallery.png",
    src: "https://obi-photo-gallery-e-commerce.web.app/",
    title: "Photo Gallery (Basic E-commerce model by Functional React.js)",
    text: "I recently worked on this project for my portfolio websites. This is basic e-commerce model site where you can select images and then go to cart page to place the order.This site was build by using advance React features, such as Routing for page route, prop types, HOOKS(useState, useEffect, useContext). Finally, context provider used for props/data distribution.",
    id: 4,
    gitsrc: "https://github.com/obi-niemur/first-e-commerce-mdel-in-react",
  },

  {
    img: "./images/bitcoin.png",
    src: "https://github.com/obi-niemur/IOS-APP-Bitcoin-Tracker.git",
    title: "Bitcoin Tracker by using Swift (IOS App)",
    text: "This IOS app was one of my college indiviual project. This app calls free coin API that update the price every three hours. Swift language was used to create this APP. the price will show in 21 different currencies.",
    id: 5,
  },
  {
    img: "./images/gaming.jpg",
    src: "https://obigaminghub.vercel.app/",
    title: "Gaming Hub (React and Typescript Project)",
    text: "This project is built using React and TypeScript, leveraging the rawg gaming API to fetch all the necessary data. The website provides various features, including genre-based filtering, platform-based sorting, and the ability to order the results. Additionally, users can conveniently search for their favorite games using the search box.",
    id: 6,
    gitsrc: "https://github.com/obi-niemur/Obi_Gaming-Hub.git",
  },
];