import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import styled from "styled-components";

const CertificateObi = styled.div`
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align:center;
  position: relative;
  margin-bottom: 20px;

`
const Certificates  = ()=>{
    return(
        <Container>
            <AnimationOnScroll animateIn="animate__fadeInLeftBig">
                {/* using same css as contact backgroud */}
                <Col className='contact-background' id="obiCertificates"> 
                    <CertificateObi> Certifications </CertificateObi>
                    <blockquote className="embedly-card" data-card-controls="0" ><h4><a href="https://www.linkedin.com/learning/certificates/4caab629db76197be199bf539e095eded44d193e62bdbe2f7c09d84023752d5d">Certificate of Completion</a></h4><p>By: Shaun Wassell Course You've learned React and can create amazing front-end interfaces. By combining your front-end UI with a back-end solution and cloud hosting, you can build powerful and interactive full-stack applications.</p></blockquote>
                </Col>
            </AnimationOnScroll>
        </Container>
    )     
    
}

export default Certificates