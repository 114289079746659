import React from 'react'
import styled from "styled-components";

const FooterClass = styled.div`
  padding: 25px 20px 20px 20px;
    background-color: #000205;
    margin-top: 20px;
    opacity: 0.9;
    p{
    text-align: center;
    color: #fff;
}
 
`



function Footer(){
    return(
        <FooterClass>
            <p>Copyright © 2023 Niemur Rahman Obi  <br></br>All Rights Reserved.</p>
        </FooterClass>
    )
}
export default Footer