import React from "react";
import styled from "styled-components";



const Section = styled.div`
    background-color: #ffffff;
    border: solid 1px;
    margin: 4px;
    width: 400px;
    max-width:100%;
    height: 20px;
    margin - bottom: 22px;
    border - radius: 10px;
    display: flex;
    justify - content: left;
    font-size: 10px;
    color: #000;
`;

const Weekday = styled.div`
    display: flex;
    justify-content: center;
    width: 50px;
    max-width:100%;
    height: 18px;
    color: gray;
    border-right: solid 1px gray;

`;

const Progressbar = styled.div`
        width: ${({ Progress }) => Progress};
        max-width:100%;
        height: 20px;
        max-height:100%;
        background-color:  ${({ Progress }) => {
    if (Progress === "100%") return "#0078ff";
    else if (Progress >= "80%") return "#0d86f0";
    else if (Progress >= "60%") return "#0d86f0";
    else if (Progress < "50%") return "#0d86f0";
    }};}
    `;

function Skills({ name, Progress }) {
    return (

        <Section>

            <Weekday>
                {name}
            </Weekday>
            <Progressbar Progress={Progress} />

        </Section>
        

    )
}

export default Skills