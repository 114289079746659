import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

import emailjs from 'emailjs-com';
import styled from "styled-components";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import React from 'react';



const Contactme = styled.div`
  text-decoration: none;
  font-size: 1.8rem;
  font-weight: bold;
  text-transform: uppercase;
  text-align:center;
  position: relative;
  margin-bottom: 20px;

`


function Contact() {
    // const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_9fxry8m', 'template_agjanks', e.target, 'BLNTWtMNB3_OB-JgX')
            .then((result) => {
                console.log(result.text);
            }, (error) => {
                console.log(error.text);
            });

        e.target.reset()
    };
    return (


        <Container>
            <Row>
                <Col className='contact-background'>
                    <AnimationOnScroll animateIn="animate__fadeInLeftBig">
                        <Contactme id='contact' > Send a Message to me</Contactme>
                    </AnimationOnScroll>
                    <AnimationOnScroll animateIn="animate__bounceIn">  {/*this will animatie the form */}

                        <Form onSubmit={sendEmail}>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Control
                                    type="Text"
                                    placeholder="Your Name"
                                    name='name'

                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Control
                                    type="email"
                                    placeholder="Your Email"
                                    name='email'
                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Control
                                    type="Text"
                                    placeholder="Subject"
                                    name='subject'

                                />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                <Form.Control
                                    as="textarea" rows={3}
                                    placeholder="Message"
                                    name="message"


                                />


                            </Form.Group>

                            <input type='submit' value='send message' />

                        </Form>
                    </AnimationOnScroll>
                </Col>
            </Row>

        </Container>

    );
}

export default Contact;